<template>
  <div id="StudentGrow">
    <div class="info">
      <img src="@/assets/images/scene/social_banner_bg.png" alt="" />
      <div>
        <h2>应用场景 <span>「</span> 社会服务 <span>」</span></h2>
        <!-- <p>Application Scenario-Social Service</p> -->
      </div>
    </div>
    <h2>应用场景</h2>
    <!-- <p>Application Scenario</p> -->
    <div class="a_s">
      <div class="a_s_box">
        <img src="@/assets/images/scene/social_a_s2.png" alt="" />
        <div class="content">
          晓羊集团社会服务集合学生多元智能成长评价、数字少年宫、预约叫号系统、人事招聘系统、智能校牌卡、大数据决策支撑系统、4:30课堂资源系统、招生迎新、家校共育等系统。
          学校综合素质评价、少年宫管理、预约叫号、人事招聘、校园一卡通、大数据看板、招生迎新等场景。
        </div>
      </div>
    </div>
    <!-- <h2>案例</h2>
    <p>Case Study</p>
    <div class="c_s">
      <h2>暂无</h2>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'StudentGrow',
  data() {
    return {}
  },
  mounted() {
    this.$store.commit('setPcHeaderIsTransparent', 'transparent')
  },
}
</script>

<style lang="scss" scoped>
%StudentGrow {
  h1,
  h2,
  h3,
  h4 {
    color: #333;
  }
  width: 100%;
  position: relative;
  background: #f6f5f5;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('height', 1255);
  .info {
    position: relative;
    img {
      width: 100%;
    }
    div {
      text-align: center;
      @include px2vw('width', 880);
      @include px2vw('padding', 18);
      @include px2vw('padding-bottom', 60);
      background: rgba(0, 0, 0, 0.3);
      color: $--color-white;
      text-shadow: 0px 2px 12px rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 37%;
      left: 26%;
      h2 {
        @include px2vw('font-size', 56);
        font-weight: normal;
        margin: 32px 0 0;
        // margin-top: 32px;
        color: $--color-white;
        letter-spacing: 4px;
        span {
          @include px2vw('font-size', 32);
          vertical-align: top;
          font-weight: bold;
        }
        span:nth-child(2) {
          vertical-align: bottom;
        }
      }
      p {
        margin: 0;
        @include px2vw('font-size', 33);
        @include px2vw('margin-top', 25);
        letter-spacing: 1px;
      }
    }
  }
  > h2 {
    font-size: 32px;
    text-align: center;
    @include px2vw('margin-top', 125);
  }
  > p {
    text-align: center;
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .a_s {
    width: 90%;
    // height: 400px;
    max-width: 1320px;
    margin: 50px auto;
    // background: url('~@/assets/images/scene/social_a_s1.png') no-repeat;
    // background-size: 100% 100%;
    padding: 50px 0;
    background-color: #f2f2f2;
    position: relative;
    display: flex;
    align-items: center;
    .a_s_box {
      background: #f2f2f2;
      margin: 0 60px;
      // padding: 70px 50px 50px 50px;
      z-index: 2;
      img {
        // @include px2vw('width', 440);
        width: 35%;
        vertical-align: middle;
      }
      .content {
        margin-left: 5%;
        display: inline-block;
        width: 60%;
        line-height: 32px;
        font-size: 18px;
        text-align: justify;
        color: #666;
        vertical-align: middle;
      }
    }
  }
}
.view-pc > #StudentGrow {
  @extend %StudentGrow;
  padding-bottom: 100px;
  > h2 {
    font-size: 36px;
    margin: 50px auto;
  }
  .a_s {
    margin-bottom: 0;
  }
  @media screen and (max-width: 1100px) {
    .a_s {
      width: calc(90% - 120px);
    }
  }
  @media (min-width: 570px) and(max-width:1440px) {
    .c_s {
      h2 {
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
  }
}

.view-mobile > #StudentGrow {
  @extend %StudentGrow;
  // @include px2vw('height', 3000);
  > h2 {
    font-size: 22px !important;
  }
  .info {
    margin-top: 44px;
    height: 153px;
    overflow: hidden;
    display: flex;
    div {
      width: 100vw;
      height: 75%;
      padding: 10% 0 0;
      // padding-top: 10%;
      top: 0;
      left: 0;
      h2 {
        font-size: 22px;
        margin-top: 15px;
      }
      p {
        font-size: 10px;
      }
    }
  }
  > p {
    font-size: 14px;

    &::after {
      left: 40% !important;
      margin-top: 35px;
    }
  }
  .student-grow-box {
    margin-top: 40px;
  }
  .a_s {
    background: #f2f2f2;
    padding: 0 20px;
    width: calc(90% - 40px);
    height: 100%;
    margin: 30px 5% 0;
    // margin-bottom: 0;
    .a_s_box {
      flex-wrap: wrap;
      width: 90%;
      padding: 30px 20px;
      margin: 0 !important;
      img {
        width: 100%;
      }
      .content {
        font-size: 14px;
        line-height: 28px;
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      &::after {
        left: 45%;
        margin-top: 35px;
      }
    }
  }
}
</style>
